<template>
  <div class="organ-management-page" v-loading="loading">
    <el-card shadow="always" class="table-block" style="margin-top:0px">
    <el-row style="margin:-20px">
      <el-col :span="24">
        <el-card shadow="never">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small" style="margin-bottom:-18px;">
            <el-form-item label="角色名称:">
              <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
            </el-form-item>
            <el-form-item label="设置时间:">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="form.date1"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="clickquery" icon="el-icon-search" type="primary" :disabled="disabledFalg">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    </el-card>
    <el-card shadow="always" class="table-block">
      <div style="margin-bottom: 20px">
        <el-button @click="showDialog" type="primary" plain icon="el-icon-plus">添加</el-button>
        <el-button @click="clickdel" plain icon="el-icon-setting" type="danger">删除</el-button>
      </div>
      <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}} </span>条信息</div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        height="456"
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center', color: '#999', background: '#EEF1FC' }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        @row-click="handleClickTableRow"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="roleRemark" label="备注"> </el-table-column>
        <el-table-column prop="createTime" label="设置时间"> </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button @click="seeClick(scope.row)" type="text">查看</el-button>
            <el-button @click="modifyClick(scope.row)" class="updatebutton" type="text">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <!-- 查看 -->
    <el-dialog
      title="详细信息"
      :visible.sync="dialogVisible"
      width="32%"
      :before-close="handleClose"
      v-loading="seeloading"
    >
      <el-card class="seelialog">
        <p><span>角色名称：</span>{{ seelist.roleName }}</p>
        <p><span>备&emsp;&emsp;注：</span>{{ seelist.roleRemark }}</p>
        <p><span>设置时间：</span>{{ seelist.createTime }}</p>
        <p><span>更新时间：</span>{{ seelist.updateTime }}</p>
        <div>
          <!-- <p>拥有权限:</p> -->
          <div>
            <div>
              拥有权限：
              <el-tag v-for="items in powerlist" :key="items.resourceId" style="margin:0 6px 6px 0">{{ items.resourceCh }}</el-tag>
              <span v-if="powerlist.length==0" style="padding:4px;">暂无权限</span>
            </div>
            
          </div>
        </div>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改操作权限："
      :visible.sync="dialogVisiblemod"
      width="40%"
      @close="handlemodClose"
      v-loading="modifyloading"
    >
      <el-card class="right">
        <div
          v-for="item in datalist"
          :key="item.resourceId + 'item'"
          style="background-color: #fff; margin-bottom: 15px"
        >
          <div class="title">{{ item.resourceCh }}</div>
          <div style="display: flex; flex-wrap: wrap; padding: 15px 30px">
            <div v-if="item.children.length == 0">
              <el-checkbox v-model="item.value" @change="childChange">{{ item.resourceCh }}</el-checkbox>
            </div>
            <div class="permission" v-for="items in item.children" :key="items.resourceId + 'items'">
              <el-checkbox v-model="items.value" @change="childChange">{{ items.resourceCh }}</el-checkbox>
            </div>
          </div>
        </div>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblemod = false">取 消</el-button>
        <el-button type="primary" @click="confirmclk">确 定</el-button>
      </div>
      <!-- </div> -->
    </el-dialog>

    <addPermission v-if="isShow" @closeAddPermission="closeAddPermission" :AddPermission="tableData"></addPermission>
  </div>
</template>

<script>
import addPermission from '@/views/basisSet/component/addPermission'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        date1: '',
        region: '',
      },
      tableData: [],
      multipleSelection: [],
      isShow: false,
      currentPage: 1,
      pagesize: 8,
      total: 0,
      dialogVisible: false,
      dialogVisiblemod: false,
      seelist: '',
      powerlist: '',
      datalist: [],
      checkedCities: '',
      checkAll: [],
      userOptions: '',
      uservalue: '',
      modifyuser: '',
      loading: false,
      seeloading:false,
      modifyloading: false,
      powerAll:'',
      disabledFalg:false
    }
  },
  components: { addPermission },
  created() {
    this.loading = true
    this.loaddata(this.currentPage, this.pagesize)
    // 获取角色列表
    postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '03' }).then(res => {
      // console.log(res)
      this.userOptions = res
    })
  },

  methods: {
    // 获取页面初始数据
    loaddata(pageNo, pageSize, roleName, createTime) {
      postRequestAnthor('/rolecomputer/queryrolelist', { pageNo, pageSize, roleName, createTime })
        .then(res => {
          // console.log(res)
          this.total = res.count
          this.tableData = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 查询
    clickquery() {
      this.loading = true
      this.loaddata('1', '8', this.form.name, this.form.date1)
      this.disabledFalg = true
      setTimeout(()=>{
          this.disabledFalg = false
        },2000)
    },
    // 删除
    clickdel() {
      if(this.multipleSelection.length >= 1){
        this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          postRequestAnthor('/rolecomputer/deletes', {
            ids: this.multipleSelection,
          }).then(() => {
            this.loaddata(this.currentPage, this.pagesize)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          })
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除',
          // })
        })
      }
      
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => {
        return item.id
      })
    },
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.loaddata(this.currentPage, this.pagesize)
    },
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.loaddata(this.currentPage, this.pagesize)
    },
    
    closeAddPermission() {
      this.loaddata(this.currentPage, this.pagesize)
      this.isShow = false
    },
    showDialog() {
      this.isShow = true
    },
    // 查看
    seeClick(row) {
      this.dialogVisible = true
      this.seeloading = true
      // 获取用户权限数据
      postRequestAnthor('/rolecomputer/queryroleinfo', { id: row.id })
        .then(res => {
          this.seelist = res
          this.powerlist = res.resource
          this.seeloading = false
          // console.log(res, this.powerlist)
        })
        .catch(() => {
          this.seeloading = false
        })
      // console.log(row)
    },

    // 修改
    modifyClick(row) {
      this.modifyloading = true
      this.modifyuser = row
      // 自身拥有的权限
      postRequestAnthor('/rolecomputer/queryroleinfo', { id: row.id }).then(res => {
        this.poweridlist = res.resourceIds
        // console.log(this.poweridlist)
      })
      // 获取页面数据
      postRequestAnthor('/rolecomputer/menutree', {})
        .then(res => {
          // console.log(res);
          this.datalist = res
          this.datalist
            .map(obj =>{
              if(obj.children.length==0){
              return obj
            }else{
              return [obj,obj.children]
            }
            })
            .flat(Infinity)
            .map(objs => {
              objs.value = false
              if (this.poweridlist.some(obj1 => obj1 == objs.resourceId)) {
                objs.value = true
              }
            })
          // console.log(this.poweridlist)
           // 所有权限
          this.powerAll = this.datalist.map(obj => {
            if(obj.children.length==0){
              return obj
            }else{
              return [obj,obj.children]
            }

          } ).flat(Infinity)
          this.modifyloading = false
        })
        .catch(() => {
          this.modifyloading = false
        })
      this.dialogVisiblemod = true
    },
    // 修改选中项
    childChange() {
      console.log();
      this.childrenlist = this.datalist
        .map(obj => {
          if (obj.children.length == 0) {
            return obj
          } else {
            return obj.children
          }
        })
        .flat()
        .map(item => {
          if (item.value) {
            return item.resourceId
          }
        })
        .filter(a => a)

      this.Parentlist = new Set(
        this.powerAll
          .map(obj => {
            if (obj.value && obj.parentResourceId != 0) {
              return obj.parentResourceId
            }
          })
          .filter(a => a),
      )
      // console.log(this.Parentlist);
      this.checkAll = [...this.childrenlist, ...this.Parentlist]
      // console.log(this.checkAll)
      this.$forceUpdate()
    },
    // 确认修改
    confirmclk() {
      postRequestAnthor('/rolecomputer/update', {
        id: this.modifyuser.id,
        roleName: this.modifyuser.roleName,
        roleCode: this.modifyuser.roleCode,
        updateBy: 1,
        resourceIds: this.checkAll,
      }).then(res => {
        if (res == true) {
          this.loaddata(this.currentPage, this.pagesize)
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.dialogVisiblemod = false
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    handlemodClose() {
      this.dialogVisiblemod = false
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.search > .el-form-item {
  margin-right: 13px !important;
}
.seelialog {
  margin:-20px 0;
  p {
    span {
      display: inline-block;
      width: 80px;
    }
  }
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
.right {
  flex: 1;
  height: 500px;
  margin: -30px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .title {
    width: 100%;
    padding-left: 20px;
    line-height: 40px;
    border-bottom: 1px solid #ddd;
  }
  .permission {
    flex: 0.25;
    padding: 10px 10px;
  }
  
}
.dialog-footer{
  margin-top:10px;
    text-align: center;
  }
  .table-block{
    margin-top:20px;
  }
</style>
