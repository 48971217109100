<template>
  <el-dialog
    title="新增管理权限"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="50%"
    @close="handleClose"
    v-loading="loading"
  >
    <div class="main" style="margin-top:-20px;">
      <el-card class="left">
        <div class="title" style="border-bottom: 1px solid #ddd;font-size:18px;">角色分类</div>
        <div class="buttons">
          <el-menu :default-active="activeIndex" mode="horizontal" style="padding: 10px">
          <el-menu-item :index="i.toString()" v-for="(obj, i) in cityOptions"
            :key="obj.dictionaryCode"
            style="margin-top: 10px; text-align: center;border-radius: 4px;"
            @click="clicknav(obj)">{{ obj.dictionaryName }}</el-menu-item>
        </el-menu>
        </div>
      </el-card>
      <!-- 右 -->
      <el-card class="right">
        <div class="title" style="background-color: #fff; border-bottom: 1px solid #ddd; font-size: 18px">{{rolename}}权限</div>
        <div class="right_main">
          <div
          v-for="item in datalist"
          :key="item.resourceId + 'item'"
          style="background-color: #fff; padding-bottom: 15px;border-bottom: 1px solid #ddd;"
        >
          <div class="title">{{ item.resourceCh }}</div>
          <div style="display: flex; flex-wrap: wrap; padding: 0 30px">
            <div v-if="item.children.length==0">
              <el-checkbox v-model="item.value" @change="childChange(item)">{{ item.resourceCh }}</el-checkbox>
            </div>
            <div class="permission" v-for="items in item.children" :key="items.resourceId + 'items'">
              <el-checkbox v-model="items.value" @change="childChange(items)">{{ items.resourceCh }}</el-checkbox>
            </div>
          </div>
        </div>
        <!-- <p><span>角色备注：</span><input placeholder="请添加备注" v-model="remarks" /></p> -->
      </div>
        </el-card>
        
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="addpower">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
// const cityOptions = []/
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      datalist: '',
      radioval: '',
      loading: true,
      checked1: '',
      checked2: '',
      dialogVisible: true,
      cityOptions: '',
      checkAll: '',
      isIndeterminate: false,
      userdata: '',
      remarks: '',
      powerAll:'',
      Parentlist:'',
      childrenlist:'',
      activeIndex:'0',
      rolename:''

    }
  },
  props:['AddPermission'],
  created() {
    this.loaddata()
    // console.log(this.AddPermission.map(obj=>{return obj.roleName}));
  },
  methods: {
    async loaddata() {
      // 获取资源数
      await postRequestAnthor('/rolecomputer/menutree', {})
        .then(res => {
          
          // console.log(res);/
          this.datalist = res
          // 所有权限
          this.powerAll = this.datalist.map(obj => {
            if(obj.children.length==0){
              return obj
            }else{
              return [obj,obj.children]
            }

          } ).flat(Infinity)
          // console.log(this.powerAll)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      // 获取角色分类
      await postRequestAnthor('dict/getDictByCategory', { dictionaryCategoryCode: '02' }).then(res => {
        // console.log(res);
        this.cityOptions = res.map(obj=>{
          if(!this.AddPermission.map(obj=>{return obj.roleName}).some(item=>{
            return item == obj.dictionaryName
          })){
            return obj
          };
          }).filter(a => a)
          // console.log(this.cityOptions);
          this.rolename = this.cityOptions[0].dictionaryName
      })
    },

    // 右侧导航切换
    async clicknav(item) {
      this.rolename=item.dictionaryName
      this.powerAll.map(objs => {objs.value = false})
      this.userdata = item
      // await this.powermod(item.dictionaryCode, item.dictionaryName)
      await this.$forceUpdate()
    },
    // 页面关闭触发
    handleClose() {
      this.$emit('closeAddPermission')
    },
    // 多选
    childChange() {
      this.childrenlist= this.datalist
        .map(obj => {
            if(obj.children.length==0){
              return obj
            }else{
              return obj.children
            }
            
          })
        .flat()
        .map(item => {
          if (item.value) {
            return item.resourceId
          }
        })
        .filter(a => a)
      this.Parentlist =new Set(this.powerAll.map(obj=>{
        if(obj.value && obj.parentResourceId != 0) {return obj.parentResourceId}
      }).filter(a => a))
      // console.log(this.Parentlist);
      this.checkAll=[...this.childrenlist,...this.Parentlist]
      // console.log(this.checkAll);
      this.$forceUpdate()
    },
    // 确认添加
    addpower() {
      this.dialogVisible = false
      // console.log(this.userdata.dictionaryName, this.userdata.dictionaryCode, this.checkAll,this.remarks);
      if(this.checkAll.length>0){
        postRequestAnthor('/rolecomputer/addrole', {
        roleName: this.userdata.dictionaryName,
        roleCode: this.userdata.dictionaryCode,
        updateBy: 1,
        resourceIds: this.checkAll,
        roleRemark: this.remarks,
      }).then(res => {
        if(res==true){
            this.$message({
          message: '添加成功',
          type: 'success'
        });
        }
      })
      }
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath)
    // },
  },
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
}

.left {
  width: 250px;
  height: 478px !important;
  background: #fff;
}
.right {
  
  flex: 1;
  margin-left: 20px;
}
.right_main{
  height: 395px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar{
                display: none;
            }
}
.title {
  padding-left: 20px;
  line-height: 40px;
}
.buttons {
  padding: 10px;
}
.permission {
  flex: 0.25;
  padding: 10px 10px;
}
.el-menu-item.is-active {
  height: 40px;
  line-height: 40px;
  color:#333 !important;
  background: #EEF1FC !important;
  border:0;
 
}
.el-menu--horizontal > .el-menu-item{
  border:0;
}
.el-menu {
  display: flex;
  flex-direction: column;
  border: 0;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    background-color: rgb(247, 239, 239);
  }
}
</style>